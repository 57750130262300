import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AdminNavbar from '../../components/Admin/AdminNavbar';
import AdminSidebar from '../../components/Admin/AdminSidebar';
import '../../style/Admin/AdminNavbar.css';
import '../../style/Admin/AdminSideBar.css';
import '../../style/Admin/Postulations.css';
import ReactPaginate from 'react-paginate';

const Postulations = () => {
    const { jobId } = useParams();
    const [applications, setApplications] = useState([]);
    const [filteredApplications, setFilteredApplications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const applicationsPerPage = 6;

    useEffect(() => {
        const fetchApplications = async () => {
            try {
                setLoading(true);
                const response = await fetch(`http://localhost:5002/api/job-applications/${jobId}`);
                const data = await response.json();
                if (Array.isArray(data)) {
                    setApplications(data);
                    setFilteredApplications(data);
                } else {
                    console.error('Unexpected data format:', data);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des postulations:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchApplications();
    }, [jobId]);

    const handleDelete = async (id) => {
        try {
            const response = await fetch(`http://localhost:5002/api/job-applications/${id}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                setApplications(applications.filter(app => app._id !== id));
                setFilteredApplications(filteredApplications.filter(app => app._id !== id));
            } else {
                console.error('Erreur lors de la suppression de la postulation');
            }
        } catch (error) {
            console.error('Erreur lors de la suppression de la postulation:', error);
        }
    };

    // Pagination logic
    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    const offset = currentPage * applicationsPerPage;
    const currentPageApplications = filteredApplications.slice(offset, offset + applicationsPerPage);

    return (
        <div className="postulations-container">
            <AdminNavbar />
            <AdminSidebar />
            <div className="postulations-content">
                <h1>Candidatures pour l'offre d'emploi</h1>
                {loading ? (
                    <div className="loading-indicator">Chargement...</div>
                ) : (
                    <>
                        <table className="postulations-table">
                            <thead>
                                <tr>
                                    <th>Nom</th>
                                    <th>Email</th>
                                    <th>Téléphone</th>
                                    <th>Ville</th>
                                    <th>Pays</th>
                                    <th>Date de candidature</th>
                                    <th>Lettre de motivation</th>
                                    <th>CV</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentPageApplications.map((application, index) => (
                                    <tr key={application._id}>
                                        <td>{application.firstName} {application.lastName}</td>
                                        <td>{application.email}</td>
                                        <td>{application.phoneCode} {application.phoneNumber}</td>
                                        <td>{application.city}</td>
                                        <td>{application.country}</td>
                                        <td>{new Date(application.applicationDate).toLocaleDateString()}</td>
                                        <td>
                                            <a href={`http://localhost:5002/uploads/${application.coverLetter}`} download>
                                                Télécharger la lettre
                                            </a>
                                        </td>
                                        <td>
                                            <a href={`http://localhost:5002/uploads/${application.cv}`} download>
                                                Télécharger le CV
                                            </a>
                                        </td>
                                        <td>
                                            <button onClick={() => handleDelete(application._id)}>Supprimer</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <ReactPaginate
                            previousLabel={'Précédent'}
                            nextLabel={'Suivant'}
                            breakLabel={'...'}
                            pageCount={Math.ceil(filteredApplications.length / applicationsPerPage)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default Postulations;
