import React from 'react';
import { Link } from 'react-router-dom';
import '../style/NosSociétés.css';
import logo1 from '../assets/images/logo1.png';
import afs from '../assets/logoS/AFS.jpg';
import att from '../assets/logoS/ATT.jpg';
import be from '../assets/logoS/BE.jpg';
import lrc from '../assets/logoS/LRC.jpg';
import JE from '../assets/logoS/JE.jpeg';
import sabureautel from '../assets/logoS/sabureautel.jpg';
import pm from '../assets/logoS/PM.jpg';
import { GoArrowUpRight } from 'react-icons/go';

const sociétés = [
  { id: 1, title: 'JETA GROUPE', description: "Groupements d'entreprises", image: logo1, link: '/detail-societe-1', imageClass: 'logo1' },
  { id: 2, title: 'AFRICAN FRET & SERVICES', description: 'Transport et logistique', image: afs ,link:'/detail-societe-2', imageClass: 'afs' },
  { id: 3, title: 'AFRICAN TERMINALES TRANSPORT', description: 'Transport terrestre ou maritime', image: att, link: '/detail-societe-3', imageClass: 'att' },
  { id: 4, title: 'BLOUM ENERGY', description: ' Production et commercialisation de produits pétroliers', image: be, imageClass: 'be',link:'/detail-societe-4' },
  { id: 5, title: 'LE ROI DES CHANTIERS', description: "Réalisation d'infrastructures urbaines et civiles", image: lrc, imageClass: 'lrc' ,link:'/detail-societe-5' },
  { id: 6, title: 'PREMIUM MOTORS', description: 'La vente et la location de véhicules', image: pm, link: '/detail-societe-6', imageClass: 'pm' },
  { id: 7, title: 'SABUREAUTEL', description: 'Vente du matériels informatiques et développement web', image: sabureautel, imageClass: 'sabureautel' ,link:'/detail-societe-7' },
  { id: 8, title: 'JETA ENGINEERING', description: 'Innovation Energitique et Hydraulique', image: JE ,link:'/detail-societe-8', imageClass: 'je' },
  { id: 9, title: 'AFRICA DISTRIBUTION STATION & CORPORATION', description: "Limportation et l'exportation de produits", image: logo1, link:'/detail-societe-9', imageClass: 'logo1' },
];

const NosSociétés = () => {
  return (
    <section id="nos-societes">
      <div className="sociétés-section">
        <h2 className="section-title">Nos sociétés</h2>
        <div className="sociétés-container">
          {sociétés.map(société => (
            <div key={société.id} className="société-card">
              <img 
                src={société.image} 
                alt={société.title} 
                className={`société-image ${société.imageClass}`} 
              />
              <h3 className="société-title">{société.title}</h3>
              <p className="société-description">{société.description}</p>
              {société.link ? (
                <Link to={société.link} className="société-link">Voir plus <GoArrowUpRight /></Link>
              ) : (
                <a href="#" className="société-link">Voir plus <GoArrowUpRight /></a>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default NosSociétés;
