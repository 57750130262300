import React from 'react';
import '../style/Apropos.css';
import { PiStarFour } from "react-icons/pi";
import apropos from '../assets/images/apropos.png';

const Apropos = () => {
  return (
    <section id="qui-sommes-nous">
      <div className="apropos-container">
        <h1 className="apropos-title">Qui sommes nous?</h1>
        <div className="apropos-content">
          <div className="apropos-images">
            <img src={apropos} alt="A representative view of our team working" />
          </div>
          <div className="apropos-text">
            <p>JETA GROUPE est un consortium dynamique de neuf sociétés opérant dans une diversité de secteurs, offrant des solutions innovantes et des services de qualité supérieure. Avec une présence significative dans les domaines des chantiers, de la vente et de la location de voitures, des énergies renouvelables, de l'informatique et du développement, de l'ingénierie énergétique et hydraulique, ainsi que de l'import-export, JETA GROUPE s'engage à répondre aux besoins variés de ses clients tout en contribuant au développement durable et technologique.</p>
            <ul className="apropos-list">
              <li><PiStarFour /> Un large éventail de compétences et d'expériences au sein de différents secteurs, garantissant des solutions complètes et adaptées.</li>
              <li><PiStarFour /> Engagement fort envers l'innovation et le développement durable, avec des projets visant à réduire l'empreinte écologique et à promouvoir l'énergie propre.</li>
              <li><PiStarFour /> Des services fiables et de haute qualité, grâce à une gestion rigoureuse et à une attention constante aux besoins de nos clients.</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Apropos;
