import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../style/DetailSocietes3.css';
import logo from '../assets/logoS/ATT.jpg';
import { FaTruck, FaBox, FaMountain, FaLeaf, FaRoad, FaShip } from "react-icons/fa";

const servicesData = [
  { id: 1, title: "Transport de produits pétroliers", icon: <FaTruck style={{ color: 'red' }} />, description: "Solutions spécialisées pour le transport sécurisé des produits pétroliers, en respectant les normes environnementales et de sécurité." },
  { id: 2, title: "Transport de marchandises", icon: <FaBox style={{ color: 'red' }} />, description: "Services de transport de tout type de marchandises, avec une logistique optimisée pour des livraisons ponctuelles et fiables." },
  { id: 3, title: "Transport de minerais", icon: <FaMountain style={{ color: 'red' }} />, description: "Expertise dans le transport de minerais, assurant un acheminement efficace depuis les sites d'extraction jusqu'aux points de destination." },
  { id: 4, title: "Transport agroalimentaire", icon: <FaLeaf style={{ color: 'red' }} />, description: "Transport de produits agroalimentaires avec des conditions adaptées pour préserver la qualité et la fraîcheur des denrées." },
  { id: 5, title: "Transport terrestre", icon: <FaRoad style={{ color: 'red' }} />, description: "Solutions de transport routier couvrant l'ensemble du territoire africain, avec une flotte moderne et diversifiée." },
  { id: 6, title: "Transport maritime", icon: <FaShip style={{ color: 'red' }} />, description: "Services de transport maritime international, offrant des connexions directes avec les principaux ports mondiaux." }
];

const DetailSocietes3 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="societe-detail3">
      <Navbar />
      <div className="societe-detail3__content">
        <h1>AFRICAN TERMINAL TRANSPORT</h1>
        <div className="societe-detail3__wrapper">
          <div className="societe-detail3__logo-container">
            <img src={logo} alt="Logo de la société" />
          </div>
          <div className="societe-detail3__text-container">
            <p>AFRICAN TERMINAL TRANSPORT est une entreprise leader spécialisée dans le secteur du transport, offrant des solutions 
                complètes et adaptées pour répondre aux besoins variés de ses clients à travers le continent africain.
                Forte de son expertise et de son réseau étendu, la société s'engage à fournir des services de haute qualité dans 
                le respect des normes internationales. Qu'il s'agisse de transport terrestre ou maritime, de marchandises diverses, 
                de produits pétroliers, de minerais ou de denrées agroalimentaires, Elle garantit des opérations efficaces et sécurisées,
                contribuant ainsi au développement économique et logistique de la région.</p>
          </div>
        </div>

        <div className="societe-detail3__services">
          <h1>Les services de la société</h1>
          <div className="societe-detail3__services-cards">
            {servicesData.map(service => (
              <div className="societe-detail3__service-card" key={service.id}>
                {service.icon}
                <h2>{service.title}</h2>
                <p>{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DetailSocietes3;
