import React, { useState, useEffect } from 'react';
import '../../style/Admin/AdminSideBar.css';
import { FaHome, FaFileAlt, FaComments, FaBell, FaArchive, FaChevronDown } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';

const AdminSidebar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isArchivedVisible, setIsArchivedVisible] = useState(false);

    const handleNavigation = (path) => {
        navigate(path);
    };

    const toggleArchivedVisibility = (e) => {
        e.stopPropagation(); // Empêche la propagation du clic pour éviter de déclencher la navigation
        setIsArchivedVisible(!isArchivedVisible);
    };

    // Utilisation de useEffect pour maintenir l'état d'affichage en fonction de l'URL actuelle
    useEffect(() => {
        if (location.pathname === '/ArchivedMessages' || location.pathname === '/ContactMessages') {
            setIsArchivedVisible(true);
        }
    }, [location.pathname]);

    return (
        <div className="admin-sidebar">
            <ul className="admin-sidebar__list">
                <br></br>
                <br></br>
                <li className="admin-sidebar__item" onClick={() => handleNavigation('/Dashboard')}>
                    <FaHome />
                    <span>Tableau de board</span>
                    <FaChevronDown className="right-icon" />
                </li>
                <li className="admin-sidebar__item" onClick={() => handleNavigation('/Offres-de-travail')}>
                    <FaFileAlt />
                    <span>Offres de travail</span>
                    <FaChevronDown className="right-icon" />
                </li>
                <li className="admin-sidebar__item" onClick={() => handleNavigation('/ContactMessages')}>
                    <FaComments />
                    <span>Messages de contacts</span>
                    <FaChevronDown
                        className={`right-icon ${isArchivedVisible ? 'rotated' : ''}`}
                        onClick={toggleArchivedVisibility}
                    />
                </li>
                {isArchivedVisible && (
                    <li className="admin-sidebar__item" onClick={() => handleNavigation('/Messages-archivées')}>
                        <FaArchive />
                        <span>Messages archivés</span>
                    </li>
                )}
                <li className="admin-sidebar__item" onClick={() => handleNavigation('/Newsletter')}>
                    <FaBell />
                    <span>Messages de Newsletter</span>
                    <FaChevronDown className="right-icon" />
                </li>
            </ul>
        </div>
    );
};

export default AdminSidebar;
