// NosOffres.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom'; // Importer useNavigate
import '../style/NosOffres.css';
import offres from '../assets/images/offres.jpg';

const NosOffres = () => {
  const navigate = useNavigate(); // Initialiser useNavigate

  const handleButtonClick = () => {
    navigate('/Job-offres'); // Rediriger vers /job-offers
  };

  return (
    <section className="nos-offres">
      <h2 className="nos-offres-title">Nos offres de travail</h2>
      <div className="offer-card">
        <div className="offer-content">
          <h3 className="offer-title">Vous êtes à la recherche d’une poste de travail ?</h3>
          <p className="offer-description">
          Rejoignez notre équipe dynamique et faites évoluer votre carrière avec nous.
          </p>
          <button className="offer-button" onClick={handleButtonClick}> {/* Utiliser un bouton */}
            Découvrir maintenant
          </button>
        </div>
        <div className="offer-image-container">
          <img src={offres} alt="Person with a clipboard" className="offer-image" />
        </div>
      </div>
    </section>
  );
};

export default NosOffres;
