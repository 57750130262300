import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../style/Admin/AdminNavbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSignOutAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';

const AdminNavbar = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const isAuthenticated = !!token;
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [adminName, setAdminName] = useState('');
    const [adminImage, setAdminImage] = useState('');  
    const dropdownRef = useRef(null);

    useEffect(() => {
        if (isAuthenticated) {
            fetch('http://localhost:5002/api/admin/profile', {
                headers: {
                    Authorization: token,
                },
            })
                .then(res => res.json())
                .then(data => {
                    setAdminName(data.nom);
                    // Concaténation de l'URL du serveur avec le chemin de l'image
                    setAdminImage(`http://localhost:5002/photos/${data.image}`);
                })
                .catch(err => {
                    console.error('Erreur lors de la récupération du profil admin:', err);
                });
        }
    }, [isAuthenticated, token]);

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/AdminLogin');
    };

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handleEditProfile = () => {
        navigate('/ModifierProfile'); 
    };

    const handleAddAdmin = () => {
        navigate('/AjouterAdmin');
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <nav className="admin-navbar">
            <div className="admin-navbar__title">
                JETA GROUPE
            </div>
            {isAuthenticated && (
                <div className="admin-navbar__profile" ref={dropdownRef}>
                    <img
                        src={adminImage || 'default-image-url'}  // Utilisation de l'URL de l'image récupérée ou une image par défaut
                        alt="Profile"
                        className="admin-navbar__profile-image"
                        onClick={toggleDropdown}
                    />
                    <div className="admin-navbar__profile-name">{adminName}</div>
                    {isDropdownOpen && (
                        <div className="admin-navbar__dropdown">
                            <div className="admin-navbar__dropdown-item" onClick={handleEditProfile}>
                                <FontAwesomeIcon icon={faUser} />
                                <span>Modifier Profile</span>
                            </div>
                            <div className="admin-navbar__dropdown-item" onClick={handleAddAdmin}>
                                <FontAwesomeIcon icon={faUserPlus} />
                                <span>Ajouter Admin</span>
                            </div>
                            <div className="admin-navbar__dropdown-item" onClick={handleLogout}>
                                <FontAwesomeIcon icon={faSignOutAlt} />
                                <span>Déconnexion</span>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </nav>
    );
};

export default AdminNavbar;
