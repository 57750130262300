import React, { useState } from 'react';
import AdminNavbar from '../../components/Admin/AdminNavbar';
import AdminSidebar from '../../components/Admin/AdminSidebar';
import '../../style/Admin/AdminNavbar.css';
import '../../style/Admin/AdminSideBar.css';
import '../../style/Admin/AjouterOffre.css';

const AjouterOffre = () => {
    const [jobTitle, setJobTitle] = useState('');
    const [vacancies, setVacancies] = useState('');
    const [description, setDescription] = useState('');
    const [skills, setSkills] = useState('');
    const [experience, setExperience] = useState('');
    const [expirationDate, setExpirationDate] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const jobOffer = {
            jobTitle,
            vacancies,
            description,
            skills,
            experience,
            expirationDate
        };

        try {
            const response = await fetch('http://localhost:5002/api/job-offers', {  // Assurez-vous que ce chemin correspond à celui de votre API
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(jobOffer),
            });

            if (response.ok) {
                alert('Offre de travail ajoutée avec succès!');
                // Réinitialiser le formulaire ou rediriger l'utilisateur
            } else {
                alert('Échec de l\'ajout de l\'offre de travail');
            }
        } catch (error) {
            console.error('Erreur:', error);
            alert('Une erreur est survenue lors de l\'ajout de l\'offre de travail');
        }
    };

    return (
        <div className="admin-container">
            <AdminNavbar />
            <div className="admin-content">
                <AdminSidebar />
                <div className="ajouter-admin-container">
                    <h6>Ajouter une Offre de Travail</h6>
                    <br />
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="jobTitle">Titre du poste:</label>
                            <input
                                type="text"
                                id="jobTitle"
                                name="jobTitle"
                                placeholder="Saisissez le titre du poste..."
                                value={jobTitle}
                                onChange={(e) => setJobTitle(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="vacancies">Postes disponibles:</label>
                            <input
                                type="number"
                                id="vacancies"
                                name="vacancies"
                                placeholder="Saisissez le nombre des postes disponibles..."
                                value={vacancies}
                                onChange={(e) => setVacancies(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="description">Description:</label>
                            <input
                                type="text"
                                id="description"
                                name="description"
                                placeholder="Saisissez la description du poste..."
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="skills">Compétences:</label>
                            <input
                                type="text"
                                id="skills"
                                name="skills"
                                placeholder="Saisissez les compétences du poste..."
                                value={skills}
                                onChange={(e) => setSkills(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="experience">Expérience:</label>
                            <input
                                type="text"
                                id="experience"
                                name="experience"
                                placeholder="Saisissez la durée d'expérience..."
                                value={experience}
                                onChange={(e) => setExperience(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="expirationDate">Date d'expiration:</label>
                            <input
                                type="date"
                                id="expirationDate"
                                name="expirationDate"
                                value={expirationDate}
                                onChange={(e) => setExpirationDate(e.target.value)}
                            />
                        </div>
                
                        <button type="submit" className="submit-button-ajout">Ajouter Offre de travail</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AjouterOffre;
