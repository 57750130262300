import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Principale from './Principale';
import DetailSocietes1 from './pages/DetailSocietes1';
import DetailSocietes2 from './pages/DetailSocietes2';
import DetailSocietes3 from './pages/DetailSocietes3';
import DetailSocietes6 from './pages/DetailSocietes6';
import DetailSocietes8 from './pages/DetailSocietes8';
import DetailSocietes7 from './pages/DetailSocietes7';
import DetailSocietes9 from './pages/DetailSocietes9';
import DetailSocietes4 from './pages/DetailSocietes4';
import DetailSocietes5 from './pages/DetailSocietes5';
import JobOffers from './pages/JobOffers'; 
import JobApplicationForm from './pages/JobApplicationForm';
import Dashboard from './pages/Admin/Dashboard';  
import AdminLogin from './pages/Admin/AdminLogin';
import PrivateRoute from './routes/PrivateRoutes'; 
import AjouterAdmin from './pages/Admin/AjouterAdmin';
import ModifierProfile from './pages/Admin/ModifierProfile';
import Newsletter from './pages/Admin/Newsletter';
import ContactMessages from './pages/Admin/ContactMessages';
import ArchivedMessages from './pages/Admin/ArchivedMessages';
import Offres from './pages/Admin/Offres';
import Postulations from './pages/Admin/Postulations';
import AjouterOffre from './pages/Admin/AjouterOffre';
import ResetPasswordRequest from './pages/Admin/ResetPasswordRequest';
import ResetPassword from './pages/Admin/ResetPassword';

const App = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Principale />} />
          <Route path="/detail-societe-1" element={<DetailSocietes1 />} />
          <Route path="/detail-societe-2" element={<DetailSocietes2 />} />
          <Route path="/detail-societe-3" element={<DetailSocietes3 />} />
          <Route path="/detail-societe-4" element={<DetailSocietes4 />} />
          <Route path="/detail-societe-5" element={<DetailSocietes5 />} />
          <Route path="/detail-societe-7" element={<DetailSocietes7 />} />
          <Route path="/detail-societe-6" element={<DetailSocietes6 />} />
          <Route path="/detail-societe-8" element={<DetailSocietes8 />} />
          <Route path="/detail-societe-9" element={<DetailSocietes9 />} />
          <Route path="/Job-offres" element={<JobOffers />} /> 
          <Route path="/job-application-form/:jobId" element={<JobApplicationForm />} />
          {/* Route protégée pour le Dashboard */}
          <Route path="/Dashboard" element={<PrivateRoute element={<Dashboard />} />} />
          <Route path="/AjouterAdmin" element={<PrivateRoute element={<AjouterAdmin/>} />} />
          <Route path="/ModifierProfile" element={<PrivateRoute element={<ModifierProfile/>} />} />
          <Route path="/Newsletter" element={<PrivateRoute element={<Newsletter/>} />} />
          <Route path="/ContactMessages" element={<PrivateRoute element={<ContactMessages/>} />} />
          <Route path="/Messages-archivées" element={<PrivateRoute element={<ArchivedMessages/>} />} />
          <Route path="/Offres-de-travail" element={<PrivateRoute element={<Offres />} />} />
          <Route path="/postulations/:jobId" element={<PrivateRoute element={<Postulations />} />} />
          <Route path="/AjouterOffre" element={<PrivateRoute element={<AjouterOffre />} />} />
        
          {/* Route publique pour la connexion de l'administrateur */}
          <Route path="/AdminLogin" element={<AdminLogin />} />  
          <Route path="/reset-password-request" element={<ResetPasswordRequest />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
