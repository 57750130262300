import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../style/DetailSocietes5.css';
import LRC from '../assets/logoS/LRC.jpg'; // Assurez-vous que le chemin est correct
import { MdOutlineEmojiTransportation } from "react-icons/md";
import { GiWaterMill } from "react-icons/gi";
import { MdOutlineRoute } from "react-icons/md";
import { CiAirportSign1 } from "react-icons/ci";
import { GrHostMaintenance } from "react-icons/gr";
import { FaBuildingUser } from "react-icons/fa6";

const servicesData = [
  { id: 1, title: "Construction d'infrastructures de transport", icon: <MdOutlineEmojiTransportation style={{ color: 'red' }} />, description: "La construction d'infrastructures de transport comprend la planification, la conception, et la réalisation de routes, de voies ferrées, et d'aéroports pour améliorer la connectivité et faciliter les déplacements des biens et des personnes." },
  { id: 2, title: "Maintenance des infrastructures ferroviaires", icon: <GrHostMaintenance style={{ color: 'red' }} />, description: "Assurez la sécurité et la fiabilité des réseaux ferroviaires grâce à notre expertise en entretien, réparation et modernisation des voies ferrées, des ponts et des tunnels." },
  { id: 3, title: "Aménagement et entretien des voies routières", icon: <MdOutlineRoute style={{ color: 'red' }} />, description: "L'aménagement et l'entretien des voies routières comprennent la planification, la construction et la maintenance des routes pour assurer une circulation fluide et sécurisée." },
  { id: 4, title: "Réalisation de réseaux d'eau, d'électricité, et de gaz", icon: <GiWaterMill style={{ color: 'red' }} />, description: "La réalisation de réseaux d'eau, d'électricité, et de gaz consiste en l'installation et la maintenance des infrastructures nécessaires pour assurer la distribution de ces services essentiels aux foyers et aux entreprises." },
  { id: 5, title: "Entretien des infrastructures aéroportuaires", icon: <CiAirportSign1 style={{ color: 'red' }} />, description: "Nous assurons l'entretien complet des infrastructures aéroportuaires, garantissant leur sécurité, leur efficacité et leur conformité aux normes les plus strictes." },
  { id: 6, title: "Ingénierie de corps d'état", icon: <FaBuildingUser style={{ color: 'red' }} />, description: "Planifier et superviser les travaux de construction pour garantir que tous les systèmes et structures respectent les normes de qualité, de sécurité et de performance." }
];

const DetailSocietes5 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="societe-detail5">
      <Navbar />
      <div className="societe-detail5__content">
        <h1>LE ROI DES CHANTIERS</h1>
        <div className="societe-detail5__wrapper">
          <div className="societe-detail5__logo-container">
            <img src={LRC} alt="Logo de la société" />
          </div>
          <div className="societe-detail5__text-container">
            <p>La société se spécialise dans les travaux publics, particulièrement dans l'aménagement du cadre de vie. Ses activités principales englobent la construction et la gestion d'infrastructures de transport, incluant les routes, les voies ferrées, et les aéroports. En outre, la société intervient dans la réalisation d'infrastructures urbaines et civiles telles que les réseaux d'eau, d'électricité, et de gaz, ainsi que les réseaux de fibres optiques pour les infrastructures numériques. Sa mission s'étend également à l'aménagement et à l'entretien des voies routières, ferroviaires, et aéroportuaires.</p>
          </div>
        </div>

        <div className="societe-detail5__services">
          <h1>Les services de LRC</h1>
          <div className="societe-detail5__services-cards">
            {servicesData.map(service => (
              <div className="societe-detail5__service-card" key={service.id}>
                {service.icon}
                <h2>{service.title}</h2>
                <p>{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DetailSocietes5;
