import React, { useState, useEffect, useCallback } from 'react';
import '../style/Accueil.css';
import img1 from '../assets/images/img1.jpg';
import img4 from '../assets/images/img4.jpg';
import img5 from '../assets/images/img5.jpg';
import img7 from '../assets/images/img7.jpg';
import img8 from '../assets/images/img8.jpg';
import img3 from '../assets/images/img3.jpg';
import img6 from '../assets/images/img6.jpg';
import img9 from '../assets/images/img9.jpg';
import img2 from '../assets/images/img2.jpg';
import img12 from '../assets/images/12.jpg';
import img13 from '../assets/images/13.jpg';


import { IoIosArrowDropright, IoIosArrowDropleft } from "react-icons/io";

const images = [img4, img5, img7 ,img8, img3 ,img1 ,img6,img9,img2,img12,img13];

const Accueil = ({ scrollToContact, scrollToApropos }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const goToPrevious = useCallback(() => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  }, [currentIndex, isTransitioning]);

  const goToNext = useCallback(() => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    const isLastSlide = currentIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  }, [currentIndex, isTransitioning]);

  useEffect(() => {
    const interval = setInterval(() => {
      goToNext();
    }, 2000);

    return () => clearInterval(interval);
  }, [goToNext]);

  const handleTransitionEnd = () => {
    setIsTransitioning(false);
  };

  return (
    <div className="carousel">
      <div
        className={`carousel-inner ${isTransitioning ? 'transitioning' : ''}`}
        onTransitionEnd={handleTransitionEnd}
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {images.map((image, index) => (
          <div
            key={index}
            className="carousel-item"
            style={{ backgroundImage: `url(${image})` }}
          />
        ))}
      </div>
      <div className="carousel-caption">
        
        <h1>JETA GROUPE</h1>
        
        <div className="button-group">
          <button className="btn btn-primary custom-btn" onClick={scrollToApropos}>Voir plus</button>
          <button className="btn btn-primary custom-btn" onClick={scrollToContact}>Contactez-nous</button>
        </div>
      </div>
      <button className="carousel-control-prev" onClick={goToPrevious}>
        <IoIosArrowDropleft className="carousel-control-icon" />
      </button>
      <button className="carousel-control-next" onClick={goToNext}>
        <IoIosArrowDropright className="carousel-control-icon" />
      </button>
    </div>
  );
};

export default Accueil;
