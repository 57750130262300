import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../style/DetailSocietes7.css';
import sabureautel from '../assets/logoS/sabureautel.jpg'; 
import { GrPersonalComputer } from "react-icons/gr";
import { PiOfficeChairBold } from "react-icons/pi";
import { BsFillTelephoneForwardFill } from "react-icons/bs";
import { MdWeb } from "react-icons/md";
import { SiLibreofficebase } from "react-icons/si";

const servicesData = [
  { id: 1, title: "Vente de matériels informatiques", icon: <GrPersonalComputer style={{ color: 'red' }} />, description: "La société propose une large gamme de matériels informatiques de haute qualité adaptés à tous les besoins professionnels et personnels." },
  { id: 2, title: "Vente et installation de matériels bureautiques", icon: <PiOfficeChairBold style={{ color: 'red' }} />, description: "La société propose des services de vente et d'installation de matériels bureautiques, assurant des solutions complètes pour les besoins en équipement de bureau." },
  { id: 3, title: "Vente et installation d'équipements de télécommunications", icon: <BsFillTelephoneForwardFill style={{ color: 'red' }} />, description: "Vente et installation d'équipements de télécommunications : Fourniture et mise en place de solutions de communication avancées" },
  { id: 4, title: "Vente de papeteries de tout type", icon: <SiLibreofficebase style={{ color: 'red' }} />, description: "Nous proposons une large gamme de papeterie pour tous vos besoins professionnels et personnels." },
  { id: 5, title: "Développement web", icon: <MdWeb style={{ color: 'red' }} />, description: "Nous concevons des solutions web sur mesure pour répondre aux besoins uniques de votre entreprise, en combinant innovation et performance" },
];

const DetailSocietes7 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="societe-detail7">
      <Navbar />
      <div className="societe-detail7__content">
        <h1>SABUREAUTEL</h1>
        <div className="societe-detail7__wrapper">
          <div className="societe-detail7__logo-container">
            <img src={sabureautel} alt="Logo de la société" />
          </div>
          <div className="societe-detail7__text-container">
            <p>La société se spécialise dans la vente et l'installation de matériels informatiques, bureautiques, téléphoniques et de télécommunications, ainsi que de papeteries de tout type. En outre, l'entreprise offre des services de développement web, aidant les clients à concevoir et à mettre en œuvre des solutions numériques modernes pour leurs besoins commerciaux.</p>
          </div>
        </div>

        <div className="societe-detail7__services">
          <h1>Les services de SABUREAUTEL</h1>
          <div className="societe-detail7__services-cards">
            {servicesData.map(service => (
              <div className="societe-detail7__service-card" key={service.id}>
                {service.icon}
                <h2>{service.title}</h2>
                <p>{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DetailSocietes7;
