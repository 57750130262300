import React, { useRef } from 'react';
import Navbar from './components/Navbar';
import Accueil from './pages/Accueil';
import Apropos from './pages/Apropos';
import NosSociétés from './pages/NosSociétés';
import NosOffres from './pages/NosOffres';
import Contact from './pages/Contact';
import Footer from './components/Footer';

const Principale = () => {
  const contactRef = useRef(null);
  const aproposRef = useRef(null);

  const scrollToContact = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToApropos = () => {
    if (aproposRef.current) {
      aproposRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      <Navbar />
      <section id="accueil"><Accueil scrollToContact={scrollToContact} scrollToApropos={scrollToApropos} /></section>
      <section id="qui-sommes-nous" ref={aproposRef}><Apropos /></section>
      <section id="nos-societes"><NosSociétés /></section>
      <section id="contact" ref={contactRef}><Contact /></section>
      <section id="NosOffres"><NosOffres /></section>
      <Footer />
    </div>
  );
};

export default Principale;
