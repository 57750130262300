import React, { useEffect, useState } from 'react';
import AdminNavbar from '../../components/Admin/AdminNavbar';
import AdminSidebar from '../../components/Admin/AdminSidebar';
import '../../style/Admin/AdminNavbar.css';
import '../../style/Admin/AdminSideBar.css';
import '../../style/Admin/Newsletter.css';

const Newsletter = () => {
    const [subscribers, setSubscribers] = useState([]);

    useEffect(() => {
        const fetchSubscribers = async () => {
            try {
                const response = await fetch('http://localhost:5002/api/subscribe/subscribers');
                const data = await response.json();
                if (data.success) {
                    setSubscribers(data.subscribers);
                } else {
                    console.error('Failed to fetch subscribers');
                }
            } catch (error) {
                console.error('Error fetching subscribers:', error);
            }
        };

        fetchSubscribers();
    }, []);

    const deleteSubscriber = async (id) => {
        try {
            const response = await fetch(`http://localhost:5002/api/subscribe/subscriber/${id}`, {
                method: 'DELETE',
            });
            const data = await response.json();
            if (data.success) {
                setSubscribers(subscribers.filter(subscriber => subscriber._id !== id));
            } else {
                console.error('Failed to delete subscriber');
            }
        } catch (error) {
            console.error('Error deleting subscriber:', error);
        }
    };

    return (
        <div className="newsletter-container">
            <AdminNavbar />
            <div className="newsletter-content">
                <AdminSidebar />
                <h2 className="newsletter-title">Abonnés à la Newsletter</h2>
                <div className="table-container">
                    <table className="styled-table">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Email</th>
                                <th>Action</th> {/* New column for delete button */}
                            </tr>
                        </thead>
                        <tbody>
                            {subscribers.map((subscriber, index) => (
                                <tr key={subscriber._id}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <a href={`mailto:${subscriber.email}`} className="email-link">
                                            {subscriber.email}
                                        </a>
                                    </td>
                                    <td>
                                        <button
                                            className="delete-button-new"
                                            onClick={() => deleteSubscriber(subscriber._id)}
                                        >
                                            Supprimer
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Newsletter;
