// components/PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element: Component, ...rest }) => {
  // Récupérer le token depuis le localStorage
  const token = localStorage.getItem('token');

  // Vérifier si le token est présent
  const isAuthenticated = !!token;

  return isAuthenticated ? Component : <Navigate to="/AdminLogin" replace />;
};

export default PrivateRoute;
