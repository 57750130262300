import React, { useState } from 'react';
import axios from 'axios';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'; // Importation des icônes pour les yeux
import AdminNavbar from '../../components/Admin/AdminNavbar';
import AdminSidebar from '../../components/Admin/AdminSidebar';
import '../../style/Admin/AdminNavbar.css';
import '../../style/Admin/AdminSideBar.css';
import '../../style/Admin/AjouterAdmin.css';

function AjouterAdmin() {
  const [nom, setNom] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // État pour la visibilité du mot de passe
  const [poste, setPoste] = useState('');
  const [image, setImage] = useState(null); 
  const [message, setMessage] = useState('');

  const validateNom = (nom) => /^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/.test(nom);
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const validatePassword = (password) => /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(password);
  const validatePoste = (poste) => /^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/.test(poste);
  const validateImage = (image) => image && image.size <= 5 * 1024 * 1024 && ['image/jpeg', 'image/png', 'image/gif'].includes(image.type);

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    if (validateImage(selectedImage)) {
      setImage(selectedImage);
    } else {
      setMessage('Veuillez sélectionner une image valide (JPEG, PNG, GIF) et de taille inférieure à 5MB.');
      setImage(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateNom(nom)) {
      setMessage('Le nom ne doit contenir que des lettres et des espaces.');
      return;
    }

    if (!validateEmail(email)) {
      setMessage('Veuillez entrer un email valide.');
      return;
    }

    if (!validatePassword(password)) {
      setMessage('Le mot de passe doit contenir au moins 8 caractères, dont une lettre et un chiffre.');
      return;
    }

    if (!validatePoste(poste)) {
      setMessage('Le poste ne doit contenir que des lettres et des espaces.');
      return;
    }

    if (!image) {
      setMessage('Veuillez sélectionner une image valide.');
      return;
    }

    const formData = new FormData();
    formData.append('nom', nom);
    formData.append('email', email);
    formData.append('password', password);
    formData.append('poste', poste);
    formData.append('image', image); 

    try {
      const response = await axios.post('http://localhost:5002/api/admin/register', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessage(response.data.msg);
    } catch (error) {
      if (error.response && error.response.data) {
        setMessage(error.response.data.msg);
      } else {
        setMessage('Erreur lors de la création de l\'admin');
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="admin-container">
      <AdminNavbar />
      <div className="admin-content">
        <AdminSidebar />
        <div className="ajouter-admin-container">
          <h6>Ajouter un Admin</h6>
          <form onSubmit={handleSubmit} className="ajouter-admin-form">
            <div className="form-group">
              <label htmlFor="nom">Nom:</label>
              <input
                type="text"
                id="nom"
                value={nom}
                onChange={(e) => setNom(e.target.value)}
                required
                placeholder="Saisissez votre nom..."
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Saisissez votre Email..."
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Mot de passe:</label>
              <div className="password-input-container2">
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  placeholder="Saisissez votre Mot de passe..."
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="toggle-password-visibility2"
                >
                  {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                </button>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="poste">Poste:</label>
              <input
                type="text"
                id="poste"
                value={poste}
                onChange={(e) => setPoste(e.target.value)}
                placeholder="Saisissez votre poste..."
              />
            </div>
            <div className="form-group">
              <label htmlFor="image">Image:</label>
              <input
                type="file"
                id="image"
                accept="image/*"
                onChange={handleImageChange}
              />
            </div>
            {message && <p className="message">{message}</p>}
            <button type="submit" className="submit-button-ajout">Ajouter Admin</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AjouterAdmin;
