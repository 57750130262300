import React from 'react';
import { useNavigate } from 'react-router-dom';
import AdminNavbar from '../../components/Admin/AdminNavbar';
import AdminSidebar from '../../components/Admin/AdminSidebar';
import '../../style/Admin/AdminNavbar.css';
import '../../style/Admin/AdminSideBar.css';
import '../../style/Admin/Dashboard.css'; 
import admindash from '../../assets/images/admindash.png';

const AdminDashboard = () => {
  const navigate = useNavigate();

  const handleAddAdminClick = () => {
    navigate('/AjouterAdmin'); // Adjust the path to match your route for adding an admin
  };

  return (
    <div className="dashboard-wrapper">
      <AdminNavbar />
      <AdminSidebar />
      <div className="dashboard-content">
        <div className="dashboard-container">
          <div className="congrats-card">
            <div className="congrats-text">
              <h5>Bienvenue Admin! 🎉</h5>
              <p>Ici vous pouvez créer d'autres administrateur</p>
              <button className="view-badges-btn" onClick={handleAddAdminClick}>Ajouter Admin</button>
            </div>
            <div className="congrats-image">
              <img src={admindash} alt="Man working on laptop" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
