import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../style/DetailSocietes1.css';
import logo from '../assets/images/logo1.png';
import { MdSettingsInputComponent } from "react-icons/md";
import { FaBusinessTime, FaChartLine, FaMoneyCheckAlt, FaProjectDiagram, FaRegHandshake } from "react-icons/fa";

const servicesData = [
  { id: 1, title: "Prise de participations", icon: <FaBusinessTime style={{ color: 'red' }} />, description: "Acquisition de parts dans des entreprises, sociétés civiles, commerciales, ou financières, ainsi que dans des groupements d'entreprises ou d'intérêt économique." },
  { id: 2, title: "Gestion d'entreprises et de groupements", icon: <MdSettingsInputComponent style={{ color: 'red' }} />, description: "Participation directe ou indirecte à la gestion des entreprises, sociétés et groupements dans lesquels nous investissons." },
  { id: 3, title: "Étude de projets", icon: <FaChartLine style={{ color: 'red' }} />, description: "Analyse approfondie et étude de faisabilité pour tous types de projets industriels, commerciaux ou immobiliers." },
  { id: 4, title: "Financement de projets", icon: <FaMoneyCheckAlt style={{ color: 'red' }} />, description: "Solutions de financement adaptées pour soutenir la mise en œuvre et le développement de projets diversifiés." },
  { id: 5, title: "Mise en oeuvre de projets", icon: <FaProjectDiagram style={{ color: 'red' }} />, description: "Planification et réalisation de projets industriels, commerciaux ou immobiliers, avec une gestion rigoureuse et efficace." },
  { id: 6, title: "Suivi de projets", icon: <FaRegHandshake style={{ color: 'red' }} />, description: "Suivi continu et évaluation des projets pour assurer leur réussite et leur rentabilité, tant pour notre propre compte que pour le compte de tiers." }
];

const DetailSocietes1 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="societe-detail1">
      <Navbar />
      <div className="societe-detail1_content">
        <h1>JETA GROUPE HOLDING</h1>
        <div className="societe-detail1__wrapper">
          <div className="societe-detail1__logo-container">
            <img src={logo} alt="Logo de la société" />
          </div>
          <div className="societe-detail1_text-container">
            <p>JETA GROUPE HOLDING est une société spécialisée dans la prise de participations au sein de diverses entreprises et groupements,
               qu'ils soient civils, commerciaux, financiers, ou industriels. Sa mission est de soutenir et de gérer efficacement ces entités,
                en offrant des services complets allant de l'étude et du financement à la mise en œuvre et au suivi de projets variés.
                 En tant que partenaire stratégique, Elle s'engage à stimuler la croissance et le succès de ses investissements
                  tout en fournissant des solutions sur mesure pour répondre aux besoins spécifiques de chaque projet.</p>
          </div>
        </div>

        <div className="societe-detail1__services">
          <h1>Les services de la société</h1>
          <div className="societe-detail1__services-cards">
            {servicesData.map(service => (
              <div className="societe-detail1__service-card" key={service.id}>
                {service.icon}
                <h2>{service.title}</h2>
                <p>{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DetailSocietes1;
