import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../style/DetailSocietes4.css';
import BE from '../assets/logoS/BE.jpg';
import { AiOutlineProduct } from "react-icons/ai";
import { GiSellCard } from "react-icons/gi";
import { BsFillFuelPumpDieselFill } from "react-icons/bs";
import { RiOilFill } from "react-icons/ri";
import { MdImportExport } from "react-icons/md";

const servicesData = [
  
  { id: 1, title: "Négoce", icon: <GiSellCard style={{ color: 'red' }} />, description: "Gestion efficace de l'achat et de la vente de biens pour optimiser vos transactions commerciales." },
  { id: 2, title: "Distribution de carburant", icon: <BsFillFuelPumpDieselFill style={{ color: 'red' }} />, description: "Nous offrons une distribution efficace et fiable de carburant pour tous vos besoins." },
  { id: 3, title: "Commercialisation de lubrifiants", icon: <RiOilFill style={{ color: 'red' }} />, description: "Nous proposons une gamme complète de lubrifiants pour améliorer les performances et la durabilité de vos équipements industriels." },
  { id: 4, title: "Importation et exportation de produits pétroliers", icon: <MdImportExport style={{ color: 'red' }} />, description: "Gestion efficace des flux de produits pétroliers à l'international pour répondre aux besoins du marché global." },
  { id: 5, title: "Production et commercialisation de produits pétroliers et de leurs dérivés", icon: <AiOutlineProduct style={{ color: 'red' }} />, description: "Nous produisons et commercialisons des produits pétroliers ainsi que leurs dérivés pour répondre à vos besoins énergétiques et industriels." },
];

const DetailSocietes4 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="societe-detail4">
      <Navbar />
      <div className="societe-detail4__content">
        <h1>BLOUM ENERGY</h1>
        <div className="societe-detail4__wrapper">
          <div className="societe-detail4__logo-container">
            <img src={BE} alt="Logo de la société" />
          </div>
          <div className="societe-detail4__text-container">
            <p>Bloum energy est principalement active dans le secteur du pétrole et de ses dérivés. Elle est impliquée dans tout le processus, de la production à la vente finale au consommateur. Ses activités couvrent un large spectre, allant de la production et de la commercialisation de produits pétroliers et de leurs dérivés, jusqu'au négoce, à la distribution de carburant, à la commercialisation de lubrifiants, et aux activités d'importation et d'exportation de ces produits. La société possède également une station-service et une boutique, ce qui lui permet d'être en contact direct avec les consommateurs finaux.
            </p>
          </div>
        </div>

        <div className="societe-detail4__services">
          <h1>Les services de BLOUM ENERGY</h1>
          <div className="societe-detail4__services-cards">
            {servicesData.map(service => (
              <div className="societe-detail4__service-card" key={service.id}>
                {service.icon}
                <h2>{service.title}</h2>
                <p>{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DetailSocietes4;
