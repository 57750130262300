import React, { useRef, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../style/DetailSocietes2.css'; 
import AFS from '../assets/logoS/AFS.jpg'; 
import { GiAirplaneDeparture } from "react-icons/gi";
import { FaTruck } from "react-icons/fa";
import { GrUserPolice } from "react-icons/gr";
import { BsFillBoxSeamFill } from "react-icons/bs";
import { FaSailboat } from "react-icons/fa6";

const servicesData = [
  
  { id: 1, title: "Transport routier et maritime des marchandises", icon: <FaSailboat style={{ color: 'red' }} />, description: "Le transport routier et maritime des marchandises assure une distribution fluide et économique des biens à l'échelle nationale et internationale." },
  { id: 2, title: "Dépotage/Entreposage", icon: <BsFillBoxSeamFill style={{ color: 'red' }} />, description: "Le dépôtage/entreposage offre des solutions sécurisées pour le stockage temporaire et la gestion efficace des marchandises, permettant une organisation optimale avant leur distribution ou leur traitement ultérieur." },
  { id: 3, title: "Transport aérien de fret", icon: <GiAirplaneDeparture style={{ color: 'red' }} />, description: "Le transport aérien de fret offre une solution rapide et efficace pour acheminer des marchandises à travers le monde." },
  { id: 4, title: "Déménagement", icon: <FaTruck style={{ color: 'red' }} />, description: "Le service de déménagement assure un transfert complet et sans stress de vos biens d’un lieu à un autre." },
  { id: 5, title: "Déclaration en douane", icon: <GrUserPolice style={{ color: 'red' }} />, description: "La déclaration en douane assure la conformité des marchandises avec les régulations internationales et locales en facilitant les formalités administratives nécessaires pour leur importation ou exportation." },
  
];

const DetailSocietes2 = () => {
  const contentRef = useRef(null);

  useEffect(() => {
    contentRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <div className="societe-detail2">
      <Navbar />
      <div className="societe-detail2__content" ref={contentRef}>
        <h1>AFRICAN FRET SERVICES</h1>
        <div className="societe-detail2__wrapper">
          <div className="societe-detail2__logo-container">
            <img src={AFS} alt="Logo de la société" />
          </div>
          <div className="societe-detail2__text-container">
            <p>African Fret Services est une jeune entreprise Gabonaise agissant dans le secteur du transport et de la logistique. Toutes les activités liées au transport de fret, y compris les transports routiers et maritimes de marchandises interurbaines, ainsi que les services de déménagement. Cela comprend également les activités administratives associées au transit des marchandises et aux déclarations en douane. En outre, l'achat, l'importation ou la location, directe ou indirecte, de tout équipement, camion, véhicule et engin en lien avec les activités mentionnées ci-dessus.</p>
          </div>
        </div>

        <div className="societe-detail2__services">
          <h1>Les services de AFS</h1>
          <div className="societe-detail2__services-cards">
            {servicesData.map(service => (
              <div className="societe-detail2__service-card" key={service.id}>
                {service.icon}
                <h2>{service.title}</h2>
                <p>{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DetailSocietes2;
