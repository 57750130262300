import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../style/DetailSocietes9.css';
import logo from '../assets/images/logo1.png';
import { MdConstruction } from "react-icons/md";
import { TbManualGearboxFilled } from "react-icons/tb";
import { MdOutlineImportExport, MdOutlineFastfood } from "react-icons/md";

const servicesData = [
  { id: 1, title: "Importation et exportation", icon: <MdOutlineImportExport style={{ color: 'red' }} />, description: "facilite le commerce international en assurant l'importation et l'exportation de marchandises variées, permettant ainsi un flux continu de produits essentiels entre les marchés locaux et internationaux." },
  { id: 2, title: "Matériaux de construction", icon: <MdConstruction style={{ color: 'red' }} />, description: "La société fournit une large gamme de matériaux de construction de haute qualité, adaptés aux besoins des projets résidentiels, commerciaux et industriels." },
  { id: 3, title: "Produits agroalimentaires", icon: <MdOutlineFastfood style={{ color: 'red' }} />, description: "La société propose une gamme complète de produits agroalimentaires, allant des denrées de base aux produits transformés, afin de satisfaire les besoins alimentaires variés des consommateurs et des entreprises locales." },
  { id: 4, title: "Fourniture d'équipements", icon: <TbManualGearboxFilled style={{ color: 'red' }} />, description: "La fourniture d'équipements consiste à offrir une gamme diversifiée d'outils et de dispositifs nécessaires pour soutenir et optimiser les opérations de nos clients dans divers secteurs, assurant ainsi une productivité et une efficacité accrues." },
];

const DetailSocietes9 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
<div className="societe-detail9">
      <Navbar />
      <div className="societe-detail9__content">
        <h1>AFRICA DISTRIBUTION STATION & CORPORATION</h1>
        <div className="societe-detail9__wrapper">
          <div className="societe-detail9__logo-container">
          <img src={logo} alt="Logo de la société" />
          </div>
          <div className="societe-detail9__text-container">
          <p>AFRICA DISTRIBUTION STATION & CORPORATION se spécialise dans l'importation et l'exportation, en traitant une variété de biens, y compris les matériaux de construction, les produits agroalimentaires et divers équipements liés à leurs objectifs commerciaux. Située dans le quartier Baracuda à Owendo, la société se concentre sur la facilitation du commerce et la fourniture de biens essentiels.</p>
          </div>
        </div>

        <div className="societe-detail9__services">
          <h1>Les services de ADS&CO</h1>
          <div className="societe-detail9__services-cards">
            {servicesData.map(service => (
              <div className="societe-detail9__service-card" key={service.id}>
                {service.icon}
                <h2>{service.title}</h2>
                <p>{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DetailSocietes9;


