import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../style/DetailSocietes6.css';
import logo from '../assets/logoS/PM.jpg';
import { MdDirectionsCar, MdStore, MdEventSeat, MdBuild, MdLocalCarWash, MdLocalShipping } from "react-icons/md";

const servicesData = [
  { id: 1, title: "Importation de véhicules", icon: <MdDirectionsCar style={{ color: 'red' }} />, description: "Importation de véhicules de qualité, sélectionnés pour répondre aux standards les plus exigeants du marché." },
  { id: 2, title: "Vente de véhicules", icon: <MdStore style={{ color: 'red' }} />, description: "Large gamme de véhicules disponibles à la vente, incluant voitures particulières et engins poids lourds, pour tous types de besoins." },
  { id: 3, title: "Location de véhicules", icon: <MdEventSeat style={{ color: 'red' }} />, description: "Solutions flexibles de location de véhicules pour des courtes ou longues durées, adaptées à chaque projet." },
  { id: 4, title: "Service après-vente", icon: <MdBuild style={{ color: 'red' }} />, description: "Service après-vente de qualité, offrant des réparations, des maintenances et des conseils personnalisés." },
  { id: 5, title: "Entretien et nettoyage", icon: <MdLocalCarWash style={{ color: 'red' }} />, description: "Prestations complètes d'entretien et de nettoyage pour garantir la longévité et la propreté de vos véhicules." },
  { id: 6, title: "Négoce et logistique", icon: <MdLocalShipping style={{ color: 'red' }} />, description: "Services de négoce et de logistique pour optimiser la gestion de vos flottes et faciliter vos opérations de transport." }
];

const DetailSocietes6 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="societe-detail6">
      <Navbar />
      <div className="societe-detail6__content">
        <h1>PREMIUM MOTORS</h1>
        <div className="societe-detail6__wrapper">
          <div className="societe-detail6__logo-container">
            <img src={logo} alt="Logo de la société" />
          </div>
          <div className="societe-detail6__text-container">
            <p>PREMIUM MOTORS est une entreprise de référence spécialisée dans l'importation, la vente et la location de véhicules 
                et d'engins poids lourds. Notre mission est d'offrir à nos clients des solutions complètes et sur mesure, 
                allant de la fourniture de véhicules de qualité jusqu'aux services après-vente, d'entretien et de nettoyage.
                 Grâce à notre expertise et à notre réseau de partenaires, nous garantissons des prestations fiables et efficaces. 
                 Que ce soit pour des besoins particuliers ou professionnels, Elle est votre partenaire de confiance pour tous vos
                  besoins en mobilité et en logistique.</p>
          </div>
        </div>

        <div className="societe-detail6__services">
          <h1>Les services de la société</h1>
          <div className="societe-detail6__services-cards">
            {servicesData.map(service => (
              <div className="societe-detail6__service-card" key={service.id}>
                {service.icon}
                <h2>{service.title}</h2>
                <p>{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DetailSocietes6;
