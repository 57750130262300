import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../style/DetailSocietes8.css';
import JE from '../assets/logoS/JE.jpeg';
import { BsBuildingsFill } from "react-icons/bs";
import { MdOutlineConstruction, MdElectricalServices } from "react-icons/md";

const servicesData = [
  { id: 1, title: "Ingénierie du Bâtiment, d’Électricité et d'Hydraulique", icon: <MdElectricalServices style={{ color: 'red' }} />, description: "Notre expertise en ingénierie englobe un large spectre, allant de la conception et la planification à la réalisation concrète dans les domaines du bâtiment, de l'électricité et de l'hydraulique. Nous mettons en œuvre des solutions innovantes pour répondre aux défis spécifiques de chaque projet, intégrant les dernières avancées technologiques." },
  { id: 2, title: "Bâtiments et Travaux Publics", icon: <BsBuildingsFill style={{ color: 'red' }} />, description: "Notre engagement dans le secteur des bâtiments et travaux publics se manifeste à travers la conception, la construction et la rénovation de structures diverses. Nous nous spécialisons dans la création d'environnements fonctionnels, esthétiques et durables, qu'il s'agisse de bâtiments résidentiels, commerciaux ou d'infrastructures publiques." },
  { id: 3, title: "Fourniture de Tout Type de Matériels de BTP, Électrique et Hydraulique", icon: <MdOutlineConstruction style={{ color: 'red' }} />, description: "En tant que fournisseur de confiance, nous offrons une gamme complète de matériels de construction, électriques et hydrauliques. De la machinerie lourde aux composants électriques et hydrauliques de haute qualité, notre catalogue répond aux besoins variés de l'industrie du BTP." }
];

const DetailSocietes8 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="societe-detail8">
      <Navbar />
      <div className="societe-detail8__content">
        <h1>JETA ENGINEERING</h1>
        <div className="societe-detail8__wrapper">
          <div className="societe-detail8__logo-container">
          <img src={JE} alt="Logo de la société" />
          </div>
          <div className="societe-detail8__text-container">
          <p>L'entreprise est spécialisée dans l'ingénierie du bâtiment, de l'électricité et de l'hydraulique. Elle fournit des services de travaux publics, de fourniture de tous types de matériaux de BTP, électriques et hydrauliques, ainsi que tous les équipements industriels liés à l'activité de BTP et autres activités connexes. Elle propose également des services d'installation d'équipements, d'étude et de réalisation de grands travaux et d'ouvrages.</p>
          </div>
        </div>

        <div className="societe-detail8__services">
          <h1>Les services de JETA ENGINEERING</h1>
          <div className="societe-detail8__services-cards">
            {servicesData.map(service => (
              <div className="societe-detail8__service-card" key={service.id}>
                {service.icon}
                <h2>{service.title}</h2>
                <p>{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DetailSocietes8;


